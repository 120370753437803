/*!
 * [Preloader screen - Table of contents]
 * 1. Wrapper | .preloader
 */

body.of-hidden {
  overflow: hidden;
}

div.preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  background: $secondary-c;
  text-align: center;

  div {
    height: 80px;
    position: relative;
    top: 50%;
    margin-top: -40px;
  }

  p {
    font-size: $h3-fs;
    text-transform: uppercase;

    position: relative;
    left: -2px;

    span {
      color: $secondary-c;

      &.load-letter {
        color: $primary-c;
      }
    }

    & + p {
      font-size: $p-fs;
      color: $primary-c;
      text-transform: none;
      @include opacity(0, 0);
      @include transition(opacity .3s ease-in-out);
      top: -5px;


      &.is-loaded {
        @include opacity(100, 1);
      }
    }
  }
}
