//Styles for theme-customizer
div.show-color-picker {

  &.hidden-cp {
    @include opacity(0, 0);
  }

  @include opacity(100, 1);
  @include prefix(border-top-right-radius, 100%);
  position: fixed;
  bottom: -15px;
  left: -15px;
  width: 75px;
  height: 75px;
  z-index: 1000;
  @include elem-border-side(right);
  @include elem-border-side(top);
  cursor: pointer;
  overflow: hidden;
  @include transition(all .35s ease-in-out);

  &:hover {
    bottom: -1px;
    left: -1px;
  }

  img {
    width: 75px;
    height: 75px;
    left: 0;
    top: 0;
    position: relative;
  }
}


div#theme-modal {
  p.loading-elipsis {
    @include loading-elipsis;
    position: absolute;
    text-align: center;
    top: 50%;
    line-height: 30px;
    margin-top: -15px;
    display: none;
    margin-left: calc(50% - 24px);
  }
}

/*Color picker*/
div.color-picker {

  position: relative;
  width: 100%;

  input[type="text"] {
    display: none;
  }

  label {
    width: 100%;
    margin: 0;
  }

  div#primary-color, div#secondary-color {
    width: calc(50% - 15px);
    display: inline-block;
    vertical-align: top;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    @include media-breakpoint-down(sm) {
      display: block;
      width: 100%;
      margin: 0;
    }
  }

  span.swap {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -12px;
    font-size: 30px;
    margin-top: -10px;
    cursor: pointer;
    padding-bottom: 3px;

    &:hover {
      background-color: $primary-c;
      color: $secondary-c;
    }

    @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      margin-left: -13px;
      margin-top: -15px;
    }

    @include media-breakpoint-down(sm) {
      @include prefix(transform, rotate(-90deg));
      margin-top: -20px;
    }

  }

  div#secondary-color {
    left: 30px;

    @include media-breakpoint-down(sm) {
      left: 0!important;
      margin-top: 45px;
    }
  }

  .input-group-addon {
    border: none!important;
    @include prefix(border-radius, 0);
    padding: 0;

    i {
      display: block;
      width: 100%;
      height: 150px;
      border: solid 1px #000;
    }
  }

  .dropdown-menu {
    top: initial;
    bottom: 5px;
    left: initial;
    right: 0;
    margin: 0;
    padding: 0;
    min-width: 0;
    @include prefix(border-radius, 0);

    .colorpicker-color {
      display: none;
    }

    .colorpicker-hue, .colorpicker-alpha {
      margin: 0;
    }

    &:after, &:before {
      display: none;
    }
  }
}//end color picker


/*Border width*/
div.border-width {

  position: relative;
  padding: 30px 0;

  input[type="radio"] {
    display: none;
  }

  & > label {
    @include transition(opacity .3s ease-in-out);
    @include opacity(35, .35);
    display: inline-block;
    width: calc(100% / 3 - 10px);
    margin-right: 15px;
    line-height: 60px;
    height: 60px;
    vertical-align: top;
    text-align: center;
    cursor: pointer;

    @include media-breakpoint-down(sm) {
      display: block;
      width: 100%;
    }

    &:hover {
      @include opacity(100, 1);
    }

    &.selected {
      @include opacity(100, 1);
    }

    &[for=bw1] {
      border: solid 1px $black;
    }

    &[for=bw2] {
      border: solid 5px $black;
      line-height: 50px;
    }

    &[for=bw3] {
      border: solid 10px $black;
      margin-right: 0;
      line-height: 40px;
    }
  }
}/*end border width*/


/*Border style*/
div.border-style {

  position: relative;
  padding: 0 0 30px;

  input[type="radio"] {
    display: none;
  }

  & > label {
    @include transition(opacity .3s ease-in-out);
    @include opacity(35, .35);
    display: inline-block;
    width: calc(100% / 4 - 12px);
    margin-right: 15px;
    line-height: 50px;
    height: 60px;
    vertical-align: top;
    text-align: center;
    cursor: pointer;

    @include media-breakpoint-down(sm) {
      display: block;
      width: 100%;
    }

    &:hover {
      @include opacity(100, 1);
    }

    &.selected {
      @include opacity(100, 1);
    }

    &[for=bs1] {
      border: solid 5px $black;
    }

    &[for=bs2] {
      border: dotted 5px $black;
    }

    &[for=bs3] {
      border: dashed 5px $black;
    }

    &[for=bs4] {
      border: double 5px $black;
      margin-right: 0;
    }
  }
}//end border style


.modal-footer {
  @include media-breakpoint-down(sm) {
    padding-top: 0;
    margin-top: 0;
  }
}

ul#modal-buttons {

  margin-bottom: 20px;
  width: 100%;

  li {
    width: calc(100% / 3);
    display: inline-block;
    vertical-align: top;
    text-align: center;
    padding: 0 5px 0 0;

    .btn {
      cursor: pointer;
      @include elem-border(!important);
      border-color: $default-c-1!important;
      color: $default-c-1!important;
      &:hover {
        background: $default-c-1!important;
        color: $default-c-2!important;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }
}