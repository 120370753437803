$primary-c: #b30a0a;$secondary-c: #fff; $border-width: 5px; $bstyle: solid; $border-style: $bstyle $border-width $primary-c;

$alert-border-radius:               0px;
$alert-bg-level:                    0;
$alert-border-level:                0;
$alert-color-level:                 0;

#notifier {
  width: 100%;
  color: #fff;
  background-color: #b30a0a;
  border-color: #b30a0a; }
  .alert-danger hr {
    border-top-color: #b30a0a; }

