/*!
 * [Team section - Table of contents]
 * 1. Wrapper | #team
 * 2. Left column | .team-left
 * 3. Content wrapper | .team-member
 * 4. Content container | .team-wrap
 */

$team-h: 240px;
$team-h-resp: auto;

div#team {

  height: $team-h;

  @include media-breakpoint-down(md) {
    height: $team-h-resp;
  }

  h3 {
    @include section-title;
    left: 0;
    width: 100%;
    text-align: center;
  }

  & > div {
    @include border-side-hidden(top);

    @include media-breakpoint-down(md) {
      border: none;
      @include elem-border-side(top);
    }
  }

  &:hover .team-left {
    background: $primary-c;

    h3 {
      color: $secondary-c;
    }
  }

  //team left
  div.team-left {
    position: relative;

    @include media-breakpoint-only(md) {
      @include elem-border-side(left);
      @include elem-border-side(right);
    }
  }//end team left


  //team members cols
  div.team-member {
    @include media-breakpoint-only(md) {
      @include elem-border-side(left);

      &:last-child {
        @include elem-border-side(right);
      }
    }
  }//end teammembers


  div.team-wrap {

    cursor: pointer;

    &:hover span {
      background: $primary-c;
      color: $secondary-c;
    }

    p {
      position: relative;
      width: 100%;
      height: calc(#{$team-h} - #{$border-width});
      margin-left: -15px;

      span {
        position: absolute;

        @if $border-width == 1 {
          top: 84px;
          left: -85px;
        } @else {
          top: calc(84px - #{$border-width});
          left: calc(-85px + (#{$border-width} / 1.5));

          @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
            top: calc(84px - #{$border-width});
          }
        }

        text-transform: uppercase;
        @include prefix(transform, rotate(-90deg));
        @include prefix(transform-origin, bottom);
        @include elem-border-side(bottom);
        width: $team-h;
        padding: 5px 0 5px;
        text-align: center;
        display: block;

        @include media-breakpoint-down(md) {
          @include elem-border-side(top);
        }
      }
    }

    div {
      position: absolute;
      top: 50%;
      @include elem-border();
      @include prefix(border-radius, 100%);
      width: 150px;
      height: 150px;
      display: inline-block;
      left: 30px;
      right: 0;
      margin: -75px auto 0;
      overflow: hidden;

      @include media-breakpoint-down(md) {
        left: 40px;
      }

      img {
        -o-object-fit: cover;
        object-fit: cover;
        width: 100%;
        height: auto;
        position: relative;
        top: -1px;

        @include media-breakpoint-down(md) {
          width: 100%;
          height: auto;
          top: 0;
        }
      }
    }
  }//end team wrap

}//end div#team