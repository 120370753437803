/*!
 * [Buttons - Table of contents]
 * 1. Button
 * 2. .btn
 */
.btn, button {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background: transparent;
  border: none!important;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;

  &:focus {
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}
